<template>
  <div class="page">
    <div class="main">
      <div class="title">发卡权限</div>

      <el-table :data="tableData" border style="width: 100%; margin-top: 30px">
        <el-table-column type="index" label="序号" width="150">
        </el-table-column>
        <el-table-column prop="name" label="导师"></el-table-column>
        <el-table-column prop="address" label="发卡权限">
          <template slot-scope="scope">
            <el-radio-group
              v-model="scope.row.status"
              fill="#409EFF"
              @change="changeRule(scope.row.id, scope.row.status)"
            >
              <el-radio :label="1" fill="#409EFF">开启</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column prop="brief" label="备注">
          <template slot-scope="scope">
            <i
              class="el-icon-edit"
              @click="edit(scope.row.id, scope.row.brief)"
            ></i>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination
          :hide-on-single-page="true"
          :page-size="pageSize"
          :total="total"
          class="page-box"
          layout="prev, pager, next"
          @current-change="pageChange"
        />
      </div>
    </div>
    <el-dialog title="备注" :visible.sync="dialogVisible" width="500px">
      <el-input v-model="msg"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editBrief">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//time: 2021/1/27
export default {
  name: "CardList",
  data() {
    return {
      rule: false,
      ExpertInfo: [],
      tableData: [],
      msg: "",
      dialogVisible: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList();
  },
  methods: {
    changeRule(id, state) {
      let data = {
        id: id,
        status: state,
      };
      this.$fecth.post("expert/editStatus", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.showTips(1, "修改成功");
        } else {
          this.showTips(0, msg);
        }
      });
    },
    getList() {
      const data = {
        parent_id: this.ExpertInfo.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$fecth.post("expert/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.tableData = data.lists;
          this.total = data.total;
        } else {
          this.showTips(0, msg);
        }
      });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getList();
    },
    edit(id, brief) {
      this.id = id;
      this.msg = brief;
      this.dialogVisible = true;
    },
    editBrief() {
      let data = {
        id: this.id,
        brief: this.msg,
      };
      this.$fecth.post("expert/editBrief", data).then((res) => {
        const { code, msg } = res;
        if (code == 200) {
          this.dialogVisible = false;
          this.getList();
          this.showTips(1, "修改成功");
        } else {
          this.showTips(0, msg);
        }
      });
    },
    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>
